<template>
  <section class="w-100 position-relative">
    <div class="box-style-analytics mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <!-- <b-avatar :src="data_user.basic.avatar_url" class="mr-05"></b-avatar> -->

          <b-media vertical-align="center" class="d-flex align-items-center media-user-search">
            <template #aside>
              <div class="position-relative w-100">
                <b-avatar
                  size="md"
                  variant="light-primary"
                  class="main-avatar"
                  :src="data_user.basic.avatar_url"
                />
                  <b-avatar size="16px" class="container-avatar-table-icon" variant="light">
                    <b-img :src="getIconWhite('twitter')" :class="getIconColor('twitter', false)" fluid/>
                  </b-avatar>
              </div>
            </template>
          </b-media>
        
          <h5 class="text-blue text-underline cursor-pointer f16 m-0 ml-05">@{{data_user.basic.username }}</h5>
        </div>
        
        <div class="avenir-medium">
          {{ getFormat(data_user.parent_data.followers) }} {{ $t('campaigns.followers') }}
          <b-button variant="outline-secondary-custom-analysis" @click="redirectToSocialNet(network, data_user.basic.username)" class="outline-secondary-custom-analysis ml-05">
            <feather-icon icon="ExternalLinkIcon" size="21"></feather-icon>
          </b-button>
        </div>
      </div>
    </div>
    
    <tweets-feature :tweets="data_user.features.last_media" v-if="data_user" class="mb-2"></tweets-feature>
    
    <analysis-account  
      :is_paid="is_paid"
      :data_user="data_user"
      :network="network"
      @get_profile_url="getProfileUrl"
    />
  </section>
</template>
<script>

import { 
  BButton,
  BImg,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
import { getFormat } from "@/libs/utils/formats";
import { redirectToSocialNet } from "@/libs/utils/urls";
import { getIconWhite, getIconColor } from "@/libs/utils/icons";

export default {
  components: {
    BButton,
    BImg,
    BAvatar,
    BMedia,
    TweetsFeature: () => import('@/views/components/sidebar/twitter/TweetsFeature.vue'),
    AnalysisAccount: () => import("@/views/components/sidebar/twitch/AnalysisAccount.vue"),

  },
  props: {
    network: {
      type: String,
      required: true
    },
    data_user: {
      type: Object,
      required: true
    },
    is_paid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      getFormat,
      getIconWhite,
      getIconColor,
      redirectToSocialNet
    };
  },
  methods: {
    getProfileUrl() {
      this.$emit('get_profile_url');
    },
  }
};
</script>

<style lang="scss" scoped>
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 20px;
  background-color: white;
  border: 1px solid #E2E8F0;

  .f16 {
    font-size: 16px;
  }
  .grid-inner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .inner-box {
      border: 1px solid #E2E8F0;
      border-radius: 6px;
      box-shadow: 0 1px 4px #0b14260a;
      padding: 16px;
    }
  }
  .outline-secondary-custom-analysis {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    padding: 8px 5px;
    border-radius: 8px;
    .icon-external {
      font-size: 30px;
    }
  }
  .grid-categories {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    .img-grid-categorie {
      width: 24px;
      height: 24px;
    }
    .icon-b {
      width: 24px;
      height: 24px;
    }
  }
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;

  }
  .container-avatar-table-icon {
    position: absolute; 
    bottom: -2px; 
    right: 0;
  }
}
.ml-05 {
  margin-left: 0.5em;
}
.mr-05 {
  margin-right: 0.5em;
}
</style>